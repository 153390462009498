// @ts-check
// @ts-ignore
import storeMetrics from '@livechat/store-metrics'
import 'react-app-polyfill/ie11'
import 'js-polyfills/url'

import { wrapWithProviders } from './src/providers'
import { initSentry } from './src/services/sentry'
import { initRoutine } from '@/utils/initRoutine'
import { activeEnv, isProd, isTestEnv } from '@/utils/environment'
import { pushEvent } from '@/utils/analytics'
import {
  sendPostMessage,
  PM_EVENT,
  scrollTo,
  initializeAdblockDetector,
} from '@/utils/browser'

if (!isTestEnv) {
  initRoutine()
}

if (isProd) {
  initSentry(activeEnv)
}

/**
 * @type {import ('gatsby').GatsbyBrowser['onRouteUpdate']}
 */
export const onRouteUpdate = ({ location: { pathname, hash } }) => {
  if (hash) {
    window.setTimeout(() => scrollTo(hash), 100)
  }

  sendPostMessage(PM_EVENT.ROUTE_CHANGE, {
    pathname: `/marketplace${pathname}`,
  })

  pushEvent({ event: 'virtualPageView', payload: {} })
}

export const onInitialClientRender = () => {
  storeMetrics()

  initializeAdblockDetector()
}

export const wrapRootElement = wrapWithProviders
