import { canUseWindow, setUpBotEnginePM } from '@/utils/browser'
import { setupChat } from './chat'
import { isPublicVersion } from './environment'
import auth from './auth'

export const initRoutine = () => {
  if (canUseWindow) {
    if (isPublicVersion) {
      setupChat()
    }

    setUpBotEnginePM()
    auth.init()
  }
}
