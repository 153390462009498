/* eslint-disable no-console */
/* eslint-disable no-restricted-globals */
import startsWith from 'lodash/startsWith'

import { installApp, uninstallApp } from '@/store/installedApps'
import { EVENT_SOURCE_IDS } from '@/constants'
import { isInNestedIframe } from './iframe'

// POST MESSAGE EVENTS SUPPORTED IN THE MARKETPLACE AND AGENT APP
export const PM_EVENT = {
  BOTENGINE_INSTALLED: 'botengine_installed',
  BOTENGINE_REMOVED: 'botengine_removed',
  LOADED: 'loaded',
  RESIZED: 'resized',
  NAVIGATE: 'navigate',
  ROUTE_CHANGE: 'route_change',
  SCROLL_TO_TOP: 'scroll_to_top',
  APP_INSTALLED: 'app_installed',
  APP_REMOVED: 'app_removed',
  APP_SETTINGS_OPENED: 'app_settings_opened',
  APP_EVENT: 'app_event',
  APP_PURCHASED: 'app_purchased',
  MARKETPLACE_CART_UPDATE: 'marketplace_cart_update',
}

export const setUpPMListener = (callback) => {
  const receiveMessage = (event) => {
    if (
      startsWith(event.origin, process.env.GATSBY_IFRAME_URL) ||
      startsWith(event.origin, 'https://localhost') ||
      startsWith(event.origin, 'http://localhost') ||
      startsWith(event.origin, 'https://my.') ||
      startsWith(event.origin, 'http://my.')
    ) {
      switch (event.data) {
        case PM_EVENT.BOTENGINE_INSTALLED:
        case PM_EVENT.BOTENGINE_REMOVED:
          return callback(event)

        default:
          return false
      }
    }
  }

  window.addEventListener('message', receiveMessage, false)
}

export const setUpBotEnginePM = (store) => {
  setUpPMListener((event) => {
    switch (event.data) {
      case PM_EVENT.BOTENGINE_INSTALLED:
        return store.dispatch(
          installApp.fulfilled({
            id: process.env.GATSBY_BOTENGINE_APP_ID,
            sourceId: EVENT_SOURCE_IDS.PM_BOTENGINE,
          })
        )
      case PM_EVENT.BOTENGINE_REMOVED:
        return store.dispatch(
          uninstallApp.fulfilled({
            id: process.env.GATSBY_BOTENGINE_APP_ID,
          })
        )
      default:
        return false
    }
  })
}

export const sendPostMessage = (eventName, eventData) => {
  switch (eventName) {
    case PM_EVENT.LOADED:
    case PM_EVENT.RESIZED:
    case PM_EVENT.ROUTE_CHANGE:
    case PM_EVENT.SCROLL_TO_TOP:
    case PM_EVENT.APP_INSTALLED:
    case PM_EVENT.APP_REMOVED:
    case PM_EVENT.APP_SETTINGS_OPENED:
    case PM_EVENT.APP_EVENT:
    case PM_EVENT.APP_PURCHASED:
    case PM_EVENT.NAVIGATE:
    case PM_EVENT.MARKETPLACE_CART_UPDATE:
      const message = JSON.stringify({
        event_name: eventName,
        event_data: eventData || null,
      })

      if (isInNestedIframe()) {
        return top.postMessage(message, '*')
      }
      return parent.postMessage(message, '*')
    default:
      return false
  }
}
