import { createSlice } from '@reduxjs/toolkit'
import { createAsyncActions } from '@/utils/sagas'

const initialState = {
  hasInitialized: false,
  isFetching: false,
  error: null,
  items: [],
}

export const fetchInstalledApps = createAsyncActions('apps/installed')
export const installApp = createAsyncActions('apps/install')
export const installTrustedApp = createAsyncActions('apps/install/trusted')
export const uninstallApp = createAsyncActions('apps/uninstall')

/*
  List of installed apps on license
*/
const installedAppsSlice = createSlice({
  name: 'installedApps',
  initialState,
  reducers: {
    installAppNotLoggedIn() {},
  },
  extraReducers: {
    [fetchInstalledApps.pending]: (state) => {
      state.isFetching = true
      state.error = null
    },
    [fetchInstalledApps.fulfilled]: (state, { payload }) => {
      state.isFetching = false
      state.items = payload.items
      state.hasInitialized = true
    },
    [fetchInstalledApps.rejected]: (state, { payload }) => {
      state.isFetching = false
      state.error = payload?.error
    },
    [installTrustedApp.pending]: (state) => {
      state.isFetching = true
      state.error = null
    },
    [installTrustedApp.rejected]: (state, { payload }) => {
      state.isFetching = false
      state.error = payload?.error
    },
    [installApp.pending]: (state) => {
      state.isFetching = true
      state.error = null
    },
    [installApp.fulfilled]: (state, { payload }) => {
      state.isFetching = false
      state.items = [...state.items, payload.id]
    },
    [installApp.rejected]: (state, { payload }) => {
      state.isFetching = false
      state.error = payload?.error
    },
    [uninstallApp.pending]: (state) => {
      state.isFetching = true
      state.error = null
    },
    [uninstallApp.fulfilled]: (state, { payload }) => {
      state.isFetching = false
      state.items = state.items.filter((item) => item !== payload.id)
    },
    [uninstallApp.rejected]: (state, { payload }) => {
      state.isFetching = false
      state.error = payload?.error
    },
  },
})

export const { installAppNotLoggedIn } = installedAppsSlice.actions
export const reducer = installedAppsSlice.reducer
