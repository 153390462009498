import { isProd, PRODUCT } from '@/utils'
import { APP_WIDGET_PLACEMENT } from './utils'

export const MODAL_TYPES = {
  SETTINGS_MODAL: 'SETTINGS_MODAL',
}

export const SEARCH = {
  LIMIT: 200,
  MIN_CHARS: 2,
}

export const MAX_SEARCH_RESULTS_COUNT = 5

export const KEY_CODES = {
  ENTER: 13,
  SPACEBAR: 32,
  TAB: 9,
  ARROW_UP: 38,
  ARROW_DOWN: 40,
}

export const NOTIFICATIONS = {
  AUTO_HIDE_DELAY_TIME: 4000,
  RATING_AUTO_HIDE_DELAY_TIME: 2000,
  VARIANTS: {
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error',
    INFO: 'info',
    NOTIFICATION: 'notification',
  },
  TYPES: {
    INSTANT: 'instant',
    PAGE_LOAD: 'page load',
  },
  ERRORS: {
    ADBLOCK:
      'There’s been an error caused by AdBlock. Whitelist this site in AdBlock or add it to Trusted Sites in your browser.',
    APP_ALREADY_INSTALLED: 'Application is already installed.',
  },
}

export const CAMPAIGNS = {
  APPS_LIMIT: 6,
  TAGS: {
    APP_OF_THE_WEEK: 'app-of-the-week',
    MONTHLY_HIGHLIGHTS: 'monthly-highlights',
    APP_OF_THE_MONTH: 'app-of-the-month',
    APP_OF_THE_MONTH_RIGHT: 'app-of-the-month-right',
  },
  SEGMENTS: {
    UTM: 'utm',
    TRIAL: 'trial',
    PAID: 'paid',
    SALES_PLAN: 'sales_plan',
  },
}

export const PAYMENT = {
  DIRECT_CHARGE: 'once',
  RECURRENT_CHARGE: 'monthly',
  DIRECT_CHARGE_TYPE: 'direct_charge',
  RECURRENT_CHARGE_TYPE: 'recurrent_charge',
  NO_ACCESS_MAX_AGENTS: 3,
  ERRORS: {
    CREDIT_CARD:
      'Unfortunately, we were unable to charge your card. Please, ensure that all Credit Card details are valid and you have sufficient funds in the account.',
    BILLING_API:
      'It looks like there\u2019s been an error on our side. Don\u2019t worry, your card details are valid. Please retry or contact our support.',
    CART: "We're sorry, but there's been an error and the transaction failed. Try again or contact our Support.",
    NOT_FOUND: "This transaction doesn't exist.",
  },
  FREQUENCY: {
    ONCE: 'once',
    MONTHLY: 'monthly',
    HOURLY: 'hourly',
  },
}

export const CART = {
  BILLING_CYCLE: {
    PAY_ONCE: 'pay-once',
    PAY_MONTHLY: 'pay-monthly',
    PAY_YEARLY: 'pay-yearly',
  },
  TYPE: {
    PER_AGENT: 'per-agent',
    PER_ORGANIZATION: 'per-organization',
  },
  ERROR_CODE: {
    PRODUCTS_CART_CHECKSUM: 'PRODUCTS_CART_CHECKSUM',
    PRODUCTS_CART_CHECKOUT_STARTED: 'PRODUCTS_CART_CHECKOUT_STARTED',
  },
  ERRORS: {
    CART_LOCKED:
      'Your cart is currently blocked by the unfinished checkout process. Try again later.',
  },
}

export const CART_ITEM = {
  NOTE: {
    BILLING_ERROR: {
      BASE: 'billing:error',
      GENERIC: 'billing:error:generic',
    },
    DPS_ERROR: {
      BASE: 'dps:error',
      GENERIC: 'dps:error:generic',
      PRODUCT_INACTIVE: 'dps:error:product_inactive',
    },
  },
}

export const DIRECT_CHARGES = {
  STATUS: {
    PENDING: 'pending',
    ACCEPTED: 'accepted',
    PROCESSED: 'processed',
    SUCCESS: 'success',
    FAILED: 'failed',
  },
}

export const RECURRENT_CHARGES = {
  STATUS: {
    PENDING: 'pending',
    ACCEPTED: 'accepted',
    ACTIVE: 'active',
    DECLINED: 'declined',
    CANCELLED: 'cancelled',
    FROZEN: 'frozen',
  },
}

export const URLS = {
  APPS: {
    LINK: '/apps/',
    LABEL: 'All Apps',
  },
  NEW_AND_NOTEWORTHY: {
    LINK: '/apps/new-and-noteworthy/',
    LABEL: 'New & Noteworthy',
  },
  STAFF_TOP_PICKS: {
    LINK: '/apps/staff-top-picks/',
    LABEL: 'Staff Top Picks',
  },
  STARTER_PACK: {
    LINK: '/apps/starter-pack/',
    LABEL: 'Starter Pack',
  },
  HACKATHON_WINNERS: {
    LINK: '/apps/hackathon-winners',
    LABEL: 'Hackathon Winners',
  },
  RECOMMENDED_PARTNERS: {
    LINK: '/partners/recommended/',
    LABEL: 'Recommended',
  },
  DEVELOPERS: {
    LINK: '/developers/',
    LABEL: 'Developers',
  },
  CATEGORY: {
    ECOMMERCE: {
      LINK: '/apps/e-commerce/',
      LABEL: 'E-commerce',
    },
    CMS: {
      LINK: '/apps/cms/',
      LABEL: 'CMS Integrations',
    },
    EMAIL_MARKETING: {
      LINK: '/apps/email-marketing/',
      LABEL: 'Email Marketing',
    },
    ANALYTICS: {
      LINK: '/apps/analytics/',
      LABEL: 'Analytics',
    },
    HELPDESK: {
      LINK: '/apps/help-desk/',
      LABEL: 'Help Desk',
    },
    CRM: {
      LINK: '/apps/crm/',
      LABEL: 'CRM Connectors',
    },
    CONTACT_MANAGEMENT: {
      LINK: '/apps/contact-management/',
      LABEL: 'Contact Management',
    },
    DESKTOP_SHARING: {
      LINK: '/apps/desktop-sharing/',
      LABEL: 'Desktop Sharing',
    },
    LEAD_GENERATION: {
      LINK: '/apps/lead-generation/',
      LABEL: 'Lead Generation',
    },
    CHATBOTS: {
      LINK: '/apps/chatbots/',
      LABEL: 'Chatbots',
    },
    FILES_SHARING: {
      LINK: '/apps/files-sharing/',
      LABEL: 'Files Sharing',
    },
    SOCIAL_MEDIA: {
      LINK: '/apps/social-media/',
      LABEL: 'Social Media',
    },
  },
  EXPERTS: {
    LINK: '/partners/',
    LABEL: 'Partners',
  },
  SERVICES: {
    LINK: '/services/',
    LABEL: 'Services',
  },
  SAAS_DEALS: {
    LINK: '/saas-deals/',
    LABEL: 'SaaS Deals',
  },
  HOLIDAY_SEASON: {
    LINK: '/holiday-season/',
    LABEL: 'Holiday Season Deals',
  },
  SUPPORT_UA: {
    LINK: '/support-ukraine/',
    LABEL: 'Support for Ukraine',
  },
  OMNICHANNEL: {
    LINK: '/omnichannel-communication/',
    LABEL: 'Messaging Apps',
  },
  ENHANCE_ECOMMERCE: {
    LINK: '/enhance-ecommerce/',
    LABEL: 'Enhance Ecommerce',
  },
  BLACK_FRIDAY: {
    LINK: '/black-friday-2022/',
    LABEL: 'Black Friday Sale',
  },
  CHATBOT: {
    LINK: 'https://www.chatbot.com/',
    LABEL: 'ChatBot',
  },
  KB: {
    LINK: 'https://www.knowledgebase.com/?utm_source=marketplace&utm_medium=referral',
    LABEL: 'KnowledgeBase',
  },
  HELPDESK: {
    LINK: 'https://www.helpdesk.com',
    LABEL: 'HelpDesk',
  },
  LIVECHAT: {
    LINK: 'https://www.livechat.com',
    LABEL: 'LiveChat',
  },
}

export const PRODUCT_SUITES = [
  {
    label: URLS.LIVECHAT.LABEL,
    url: URLS.LIVECHAT.LINK,
    appPath: process.env.GATSBY_LIVECHAT_WEBSITE_URL,
    description: '360° customer service platform',
  },
  {
    label: URLS.CHATBOT.LABEL,
    url: URLS.CHATBOT.LINK,
    appPath: process.env.GATSBY_CHATBOT_APP_URL,
    description: 'AI bot platform',
  },
  {
    label: URLS.HELPDESK.LABEL,
    url: URLS.HELPDESK.LINK,
    appPath: process.env.GATSBY_HELPDESK_APP_URL,
    description: 'Support ticket system',
  },
  {
    label: URLS.KB.LABEL,
    url: URLS.KB.LINK,
    appPath: process.env.GATSBY_KB_APP_URL,
    description: 'Help center for website',
  },
]

export const AMPLITUDE_EVENTS = {
  PAGEVIEW: 'Pageview',
  BUY_FOR_CLICKED: 'Buy for X button clicked',
  NO_ACCESS_MODAL_DISPLAYED: 'No access modal displayed',
  CONTACT_ADMIN_LINK_CLICKED: 'Contact admin link clicked',
  CONTACT_DEVELOPER_CLICKED: 'Contact developer clicked',
  ADD_TO_CART_CLICKED: 'Add to cart clicked',
  HOLIDAY_SPECIALS_CHECK_IT_CLICKED:
    'Holiday specials - Check it out button clicked',
  SETTINGS_CLICKED: 'Settings button clicked',
  OPEN_APP_CLICKED: 'Open App button clicked',
  APP_INSTALLED: 'Application installed',
  APP_ADDED_TO_CART: 'Application added to cart',
}

export const EVENT_SOURCE_IDS = {
  INSTALL_BUTTON: 'install_button',
  ADD_TO_CART_BUTTON: 'add_to_card_button',
  DIRECT_CHARGE: 'direct_charge',
  RECURRENT_CHARGE: 'recurrent_charge',
  CART: 'cart',
  PM_BOTENGINE: 'post_message_botengine',
}

export const SUPPORTED_UTM_PARAMETERS = {
  UTM_SOURCE: 'utm_source',
  UTM_MEDIUM: 'utm_medium',
  UTM_CAMPAIGN: 'utm_campaign',
  UTM_CONTENT: 'utm_content',
  UTM_TERM: 'utm_term',
}

export const DEFAULT_PROTOCOL_VERSION = '3'

export const APPS_IDS = {
  HELPDESK: 'pJw7AxJZg',
  GOOGLE_HANGOUT_MEET: 'o5BfBFSWR',
}

export const EXPERT_VALIDATORS = {
  MIN_LENGTH: {
    REVIEW: 10,
    NAME: 3,
  },
  MAX_LENGTH: {
    REVIEW: 2000,
    NAME: 64,
  },
}

export const APP_VALIDATORS = {
  MIN_LENGTH: {
    REVIEW: 2,
    NAME: 2,
  },
  MAX_LENGTH: {
    REVIEW: 255,
    NAME: 64,
  },
}

export const VALIDATION_ERROR = {
  RATE: 'This field cannot be blank, please rate the app on a scale from 1-5',
  MIN_LENGTH: {
    NAME: 'Please enter your name',
    REVIEW: 'Please enter your review',
  },
  MAX_LENGTH: {
    NAME: `Name cannot be longer than ${APP_VALIDATORS.MAX_LENGTH.NAME} characters`,
    REVIEW: `Review cannot be longer than ${APP_VALIDATORS.MAX_LENGTH.REVIEW} characters`,
  },
}

export const HOOKS = {
  USE_PAGINATION: {
    ERROR_MSG: {
      ITEMS_TYPE: 'Items prop should be type of array.',
      STEP_TYPE: 'Step prop should be type of number.',
      STEP_VALUE: 'Step should be a positive number.',
    },
  },
}

export const SENTRY = {
  TAGS: {
    CHECKOUT: 'checkout',
    CART: 'cart',
    USER: 'user',
    INSTALL: 'install',
  },
}

// reference: https://github.com/livechat/rest-api/blob/253235d5b8e0fc03f396abbccd85ab50dfd17133/src/controllers/v2/baseController.coffee#L21

export const SALES_PLANS = {
  SOLO: 'solo',
  STARTER: 'starter',
  REGULAR: 'regular',
  BASIC: 'basic',
  TEAM: 'team',
  PRO: 'pro',
  BUSINESS: 'business',
  PREMIUM: 'premium',
  ENTERPRISE: 'enterprise',
  ENTERPRISEPLUS: 'enterpriseplus',
}

export const STANDARD_SALES_PLANS = [
  SALES_PLANS.SOLO,
  SALES_PLANS.STARTER,
  SALES_PLANS.REGULAR,
  SALES_PLANS.BASIC,
  SALES_PLANS.TEAM,
]

export const PREMIUM_SALES_PLANS = [
  SALES_PLANS.PRO,
  SALES_PLANS.BUSINESS,
  SALES_PLANS.PREMIUM,
  SALES_PLANS.ENTERPRISE,
  SALES_PLANS.ENTERPRISEPLUS,
]

export const CONTACTS = {
  LIVECHAT: 'apps@livechatinc.com',
}

export const FEATURED_APP_CATEGORIES = [
  {
    label: URLS.ENHANCE_ECOMMERCE.LABEL,
    url: URLS.ENHANCE_ECOMMERCE.LINK,
  },
  {
    label: URLS.NEW_AND_NOTEWORTHY.LABEL,
    url: URLS.NEW_AND_NOTEWORTHY.LINK,
  },
  {
    label: URLS.STAFF_TOP_PICKS.LABEL,
    url: URLS.STAFF_TOP_PICKS.LINK,
  },
  {
    label: URLS.STARTER_PACK.LABEL,
    url: URLS.STARTER_PACK.LINK,
  },
]

export const FEATURED_EXPERT_CATEGORIES = [
  {
    label: URLS.RECOMMENDED_PARTNERS.LABEL,
    url: URLS.RECOMMENDED_PARTNERS.LINK,
  },
]

export const SEARCH_FILTERS_KEY = 'SEARCH_LIST_FILTERS'

export const APPS_LIST_STORAGE_KEY = 'apps-integrations-collapse'
export const SERVICES_LIST_STORAGE_KEY = 'services-integrations-collapse'
export const EXPERTS_LIST_STORAGE_KEY = 'experts-integrations-collapse'

export const EXCLUDE_COMPATIBILITY_STORAGE_KEY = 'exclude-compatibility'

export const DEFAULT_PRODUCT_HEADLINE = {
  [PRODUCT.HelpDesk]: 'Power up HelpDesk with integrations.',
}

export const SORT = {
  ALPHABETICALLY: {
    VALUE: 'alphabetically',
    LABEL: 'Alphabetically',
  },
  PRICE: {
    VALUE: 'price',
    LABEL: 'Price',
  },
  POPULARITY: {
    VALUE: 'popularity',
    LABEL: 'Popularity',
  },
  RECOMMENDED: {
    VALUE: 'recommended',
    LABEL: 'Recommended',
  },
  PUBLISHED: {
    VALUE: 'published',
    LABEL: 'Recently published',
  },
}

export const SORT_DEVS = {
  DEVELOPER_NAME: { VALUE: 'developerName', LABEL: 'Developer name' },
  PRODUCTS_NUMBER: { VALUE: 'productsNumber', LABEL: 'Number of products' },
}

export const FILTERS = {
  PAYMENT: {
    ID: 'payment-type',
    LABEL: 'Payment type',
    OPTIONS: {
      FREE: {
        LABEL: 'Free',
        VALUE: 'free',
        SHORT_LABEL: 'free',
      },
      ONE_TIME_PAYMENT: {
        LABEL: 'One time payment',
        VALUE: 'once',
        SHORT_LABEL: 'one-time',
      },
      RECURRENT_PER_LICENSE: {
        LABEL: 'Per license, per month',
        VALUE: 'monthly-per-organization',
        SHORT_LABEL: 'per license',
      },
      RECURRENT_PER_AGENT: {
        LABEL: 'Per agent, per month',
        VALUE: 'monthly-per-account',
        SHORT_LABEL: 'per agent',
      },
    },
  },
  COMPATIBILITY: {
    ID: 'compatibility',
    LABEL: 'Works with',
    OPTIONS: {
      LIVECHAT: {
        LABEL: 'LiveChat',
        VALUE: PRODUCT.LiveChat,
        SHORT_LABEL: 'livechat',
      },
      HELPDESK: {
        LABEL: 'HelpDesk',
        VALUE: PRODUCT.HelpDesk,
        SHORT_LABEL: 'helpdesk',
      },
    },
  },
  SERVICE: {
    ID: 'services',
    LABEL: 'All services',
  },
  SERVICES: {
    ID: 'all-services',
    LABEL: 'All services',
    OPTIONS: {
      TRAINING: {
        ID: 'training',
        LABEL: 'Training',
        VALUE: 'training',
      },
      PRODUCT_IMPLEMENTATION: {
        ID: 'product_implementation',
        LABEL: 'Product Implementation',
        VALUE: 'product_implementation',
      },
      OTHER: {
        ID: 'other',
        LABEL: 'Other',
        VALUE: 'other',
      },
    },
  },
  LANGUAGE: {
    ID: 'language',
    LABEL: 'Language',
  },
  COUNTRY: {
    ID: 'country',
    LABEL: 'Country',
  },
  INDUSTRY: {
    ID: 'industries',
    LABEL: 'Industry',
  },
  CATEGORY: {
    ID: 'categories',
    LABEL: 'Categories',
    OPTIONS: {
      ANALYTICS: {
        ID: '11',
        LABEL: 'Analytics',
        VALUE: 'analytics',
      },
      CMS: {
        ID: '2',
        LABEL: 'CMS Integrations',
        VALUE: 'cms',
      },
      CRM: {
        ID: '5',
        LABEL: 'CRM Connectors',
        VALUE: 'crm',
      },
      CHATBOTS: {
        ID: '13',
        LABEL: 'Chatbots',
        VALUE: 'chatbots',
      },
      CONTACT_MANAGEMENT: {
        ID: '10',
        LABEL: 'Contact Management',
        VALUE: 'contact-management',
      },
      DESKTOP_SHARING: {
        ID: '12',
        LABEL: 'Desktop Sharing',
        VALUE: 'desktop-sharing',
      },
      E_COMMERCE: {
        ID: '3',
        LABEL: 'E-commerce',
        VALUE: 'e-commerce',
      },
      EMAIL_MARKETING: {
        ID: '4',
        LABEL: 'Email Marketing',
        VALUE: 'email-marketing',
      },
      FILES_SHARING: {
        ID: '7',
        LABEL: 'Files Sharing',
        VALUE: 'files-sharing',
      },
      HELPDESK: {
        ID: '6',
        LABEL: 'Help Desk',
        VALUE: 'help-desk',
      },
      INVOICING: {
        ID: '9',
        LABEL: 'Invoicing',
        VALUE: 'invoicing',
      },
      LEAD_GENERATION: {
        ID: isProd ? '-iAMKuvWg' : '1TSROuDZg',
        LABEL: 'Lead Generation',
        VALUE: 'lead-generation',
      },
      SOCIAL_MEDIA: {
        ID: '8',
        LABEL: 'Social Media',
        VALUE: 'social-media',
      },
    },
  },
  WIDGET_PLACEMENT: {
    ID: 'widget-placement',
    LABEL: 'Placement',
    OPTIONS: {
      TARGETED_MESSAGE: {
        LABEL: 'Campaigns',
        VALUE: APP_WIDGET_PLACEMENT.TARGETED_MESSAGE,
      },
      FULL_SCREEN: {
        LABEL: 'Navigation',
        VALUE: APP_WIDGET_PLACEMENT.FULL_SCREEN,
      },
      MESSAGE_BOX: {
        LABEL: 'Message Box',
        VALUE: APP_WIDGET_PLACEMENT.MESSAGE_BOX,
      },
      PLUGIN: {
        LABEL: 'Details (Chats or Tickets)',
        VALUE: APP_WIDGET_PLACEMENT.PLUGIN,
      },
      SETTINGS: {
        LABEL: 'Settings',
        VALUE: APP_WIDGET_PLACEMENT.SETTINGS,
      },
    },
  },
}

export const HOLIDAY_SPECIALS = {
  STORAGE_KEY: 'disable-holiday-special',
}

export const CAROUSEL_TYPES = {
  VIDEO: 'video',
  IMAGE: 'image',
  CARD: 'card',
}

export const PRODUCT_PAGE_TABS = {
  UPGRADES: 'upgrades',
  DESCRIPTION: 'description',
  FEATURES: 'features',
  BENEFITS: 'benefits',
  SUPPORT: 'support',
  REVIEWS: 'reviews',
  CHANGELOG: 'changelog',
}

export const DEVELOPER_PAGE_TABS = {
  APPS: 'apps',
  SERVICES: 'services',
  CONTACT: 'contact',
}

export const PRODUCT_PAGE_PARAMS = {
  HIDE_CTA: 'hideCTA',
}

export const VIEW_MODES = {
  EMBEDDED: 'embedded',
  EDITORIAL: 'editorial',
}

export const EXPERT_PAGE_TABS = {
  DESCRIPTION: 'description',
  CONTACT: 'contact',
  TESTIMONIALS: 'testimonials',
}

export const APP_CONFIG_STATUS = {
  COMPLETED: 'completed',
  UNCOMPLETED: 'uncompleted',
  UNREGISTERED: 'unregistered',
}

export const ROLE = {
  OWNER: 'owner',
  VICEOWNER: 'viceowner',
  ADMINISTRATOR: 'administrator',
  VIEWER: 'viewer',
  AGENT: 'normal',
  AGENT_ACCOUNTS: 'member',
}

export const EXPERT_TYPE_LABELS = {
  training: 'Training',
  'chatbot-building': 'Chatbot Building',
  'chat-agent-outsourcing': 'Chat Agent Outsourcing',
  'custom-development': 'Custom Development',
  'product-implementation': 'Product Implementation',
}

export const CATEGORY_LABELS = {
  CRM: URLS.CATEGORY.CRM.LABEL,
  CMS: URLS.CATEGORY.CMS.LABEL,
  'Contact management': URLS.CATEGORY.CONTACT_MANAGEMENT.LABEL,
  'Desktop sharing': URLS.CATEGORY.DESKTOP_SHARING.LABEL,
  'Email marketing': URLS.CATEGORY.EMAIL_MARKETING.LABEL,
  'Help desk': URLS.CATEGORY.HELPDESK.LABEL,
  'Social media': URLS.CATEGORY.SOCIAL_MEDIA.LABEL,
}

export const SUBSCRIPTION_EXPIRING_THRESHOLD = 4

export const BANNED_APPS = [
  {
    ownerLicense: 10027680,
    ownerOrganizationId: '22005738-f6be-49a6-abbd-75657a911b98',
    appId: 'WN-IZrCmR',
  },
]

export const PROMO_CAMPAIGN_LAYOUTS = {
  SINGLE_COLUMN: 'singleColumn',
  TWO_COLUMNS: 'twoColumns',
  THREE_COLUMNS: 'threeColumns',
}

export const PRODUCT_DETAILS_CLASS_NAME = 'productDetails'

export const BILLING_OPTIONS = {
  monthly: {
    key: 'billing-monthly',
    name: 'Billed monthly',
  },
  annually: {
    key: 'billing-annually',
    name: 'Billed annually',
  },
}

export const DESIGN_SYSTEM_THEME = 'lc-light-theme'

export const CART_ITEM_LIST_KIND = {
  INSTALLED: 'installed',
  RECOMMENDED: 'recommended',
} as const

export const CREDIT_CARD_TYPE = {
  VISA: 'visa',
  MASTER: 'master',
  MASTERCARD: 'mastercard',
  AM_EX: 'american_express',
  AMEX: 'american express',
} as const

export const DATE_FORMAT = {
  SHORT_DATE: 'MMM D, YYYY',
} as const
