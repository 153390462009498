import {
  sendPostMessage,
  PM_EVENT,
  isInIframe,
  canUseWindow,
} from '@/utils/browser'
import { getProductIFrameUrl } from './products'
import { activeProduct } from './environment'

export const navigateToIFrame = (
  e,
  path,
  product = activeProduct,
  target = '_blank'
) => {
  if (e) {
    e.preventDefault()
  }
  const isActiveProduct = product === activeProduct
  const prefix = getProductIFrameUrl(product)

  if (isInIframe() && isActiveProduct) {
    navigateWithPostMessage(path)
  } else {
    navigateWithWindowOpen(prefix, path, target)
  }
}

export const navigateWithWindowOpen = (prefix, path, target = '_blank') => {
  if (canUseWindow) {
    window.open(prefix + path, target)
  }
}

const navigateWithPostMessage = (path) => {
  sendPostMessage(PM_EVENT.NAVIGATE, { path })
}
