import { navigate } from 'gatsby'
import {
  put,
  select,
  takeEvery,
  call,
  spawn,
  all,
  take,
  delay,
} from 'redux-saga/effects'

import { userLogIn } from '@/store/user'
import {
  setCreatedChargeInfo,
  directBuyNotLoggedIn,
  createCharge,
  acceptCharge,
  declineCharge,
  archiveCharge,
  getCartInfo,
  purchaseCart,
  rejectCartCheckout,
  startPayment,
  startCartCheckout,
} from '@/store/payment'
import {
  createDirectCharge,
  acceptDirectCharge,
  declineDirectCharge,
  archiveDirectCharge,
} from '@/store/directCharges'
import {
  createRecurrentCharge,
  acceptRecurrentCharge,
  declineRecurrentCharge,
  archiveRecurrentCharge,
} from '@/store/recurrentCharges'
import {
  getItemBySlug,
  getChargeByAppId,
  getCartAgentsCountForProduct,
  getPaymentCharge,
  getPaymentItem,
  isExternalCharge,
  isProcessableCharge,
  getChargeByChargeId,
  getPaymentCart,
  getCartId,
  getChargeType,
  getItemById,
} from '@/store/selectors'
import { installApp } from '@/store/installedApps'
import directChargeApi from '@/apis/billing/directCharge'
import recurrentChargeApi from '@/apis/billing/recurrentCharge'
import taxesApi from '@/apis/billing/taxes'
import cartApi from '@/apis/cart'
import {
  PAYMENT,
  SENTRY,
  CART,
  RECURRENT_CHARGES,
  DIRECT_CHARGES,
  EVENT_SOURCE_IDS,
} from '@/constants'
import { CREATED, OK, BAD_REQUEST } from '@/constants/utils'
import { getErrorCode } from '@/utils/api'
import { pushEvent } from '@/utils/analytics'
import { SagaError, errorHandler } from '@/utils/sagas'
import {
  getExternalChargePaymentInfo,
  isEveryErrorInactiveProduct,
} from '@/utils/products'
import { load as cartLoad, create as cartCreate } from '@/store/cart'

function* acceptChargeSaga() {
  try {
    const item = yield select(getPaymentItem)
    const cart = yield select(getPaymentCart)
    const charge = yield select(getPaymentCharge)
    const isExternal = yield select(isExternalCharge)
    const chargeType = yield select(getChargeType)

    let originalCharge

    // Handle purchasing cart flow
    if (!!cart) {
      yield call(purchaseCartSaga)
      return null
    }

    // Handle changes generated outside of the marketplace, ex. KnowledgeBase payments
    if (isExternal) {
      originalCharge = yield select(getChargeByChargeId, chargeType, charge.id)
      // [Kind of legacy] Handle direct payments for single product without cart (no longer used only, in special cases)
      // Could be used for embedded-apps or places where we're selling single application without cart flow
    } else {
      originalCharge = yield select(getChargeByAppId, item)
    }

    if (item && originalCharge && charge) {
      switch (item.payment.frequency) {
        case PAYMENT.DIRECT_CHARGE:
          // Compare original with current charge. Current charge can have updated quantity
          // If the quantity is different we are going to decline the initial charge and
          // create a new one.
          if (originalCharge.quantity !== charge.quantity) {
            const {
              price,
              quantity,
              name,
              return_url,
              order_client_id,
              per_account,
              test,
            } = charge

            yield put(declineCharge({ preventRedirect: true }))

            const { status, json } = yield call(
              directChargeApi.create,
              name,
              price,
              quantity || 1,
              return_url,
              order_client_id,
              per_account,
              item.product,
              test
            )

            if (status === CREATED) {
              const createdCharge = {
                appId: item?.id,
                appSlug: item?.slug,
                ...json,
              }

              const chargeTaxes = yield call(taxesApi.getPricePreview, {
                price: json.price,
                clientId: json.order_client_id,
                productType: item.product,
              })

              const payment = getExternalChargePaymentInfo(
                json,
                PAYMENT.DIRECT_CHARGE,
                chargeTaxes?.json
              )

              yield put(createDirectCharge.fulfilled({ item: createdCharge }))
              yield put(
                setCreatedChargeInfo({
                  item: { ...item, payment },
                  charge: { ...createCharge, taxes: payment?.taxes || null },
                  chargeType: PAYMENT.DIRECT_CHARGE,
                  isExternalCharge: isExternal,
                  isProcessable: json.status === DIRECT_CHARGES.STATUS.PENDING,
                })
              )

              originalCharge = yield select(getPaymentCharge)
            } else {
              throw new SagaError('Direct charge creation failed', json, status)
            }
          }

          yield put(
            acceptDirectCharge.pending({
              paymentId: originalCharge.id,
              appId: item.id,
              slug: item.slug,
              productType: item.product,
              isExternalCharge: isExternal,
            })
          )
          break

        case PAYMENT.RECURRENT_CHARGE:
          // Compare original with current charge. Current charge can have updated months
          // If its different we are going to decline the initial charge and
          // create a new one.
          if (originalCharge.months !== charge.months) {
            const {
              price,
              quantity,
              trial_days,
              months,
              name,
              return_url,
              order_client_id,
              per_account,
              test,
            } = charge

            yield put(declineCharge({ preventRedirect: true }))

            const { status, json } = yield call(
              recurrentChargeApi.create,
              name,
              price,
              quantity || 1,
              return_url,
              order_client_id,
              trial_days,
              per_account,
              months,
              item.product,
              test
            )

            if (status === CREATED) {
              const createdCharge = {
                appId: item && item.id,
                appSlug: item && item.slug,
                ...json,
              }

              const chargeTaxes = yield call(taxesApi.getPricePreview, {
                price: json.price,
                clientId: json.order_client_id,
                productType: item.product,
              })

              const payment = getExternalChargePaymentInfo(
                json,
                PAYMENT.RECURRENT_CHARGE,
                chargeTaxes?.json
              )

              yield put(
                createRecurrentCharge.fulfilled({ item: createdCharge })
              )
              yield put(
                setCreatedChargeInfo({
                  item: { ...item, payment },
                  charge: { ...createdCharge, taxes: payment?.taxes || null },
                  chargeType: PAYMENT.RECURRENT_CHARGE,
                  isExternalCharge: isExternal,
                  isProcessable:
                    json.status === RECURRENT_CHARGES.STATUS.PENDING,
                })
              )

              originalCharge = yield select(getPaymentCharge)
            } else {
              throw new SagaError(
                'Recurrent charge creation failed',
                json,
                status
              )
            }
          }

          yield put(
            acceptRecurrentCharge.pending({
              paymentId: originalCharge.id,
              appId: item.id,
              slug: item.slug,
              productType: item.product,
              isExternalCharge: isExternal,
            })
          )
          break

        default:
      }
    } else {
      throw new SagaError(
        'Reconstruct charge with new values failed',
        originalCharge,
        charge
      )
    }
  } catch (error) {
    yield spawn(errorHandler, error, SENTRY.TAGS.CHECKOUT)
    yield put(startPayment.rejected({ error: PAYMENT.ERRORS.BILLING_API }))
    throw new SagaError('Accepting charge failed', error)
  }
}

function* declineChargeSaga({ payload }) {
  try {
    const { preventRedirect } = payload || {}
    const item = yield select(getPaymentItem)
    const charge = yield select(getPaymentCharge)
    const externalCharge = yield select(isExternalCharge)
    const isProcessable = yield select(isProcessableCharge)
    let frequency

    if (externalCharge) {
      frequency = yield select(getChargeType)
    } else {
      frequency = item?.payment?.frequency
    }

    if (item && charge && isProcessable) {
      switch (frequency) {
        case PAYMENT.DIRECT_CHARGE:
          if (charge.status === DIRECT_CHARGES.STATUS.PENDING) {
            yield put(
              declineDirectCharge.pending({
                paymentId: charge.id,
                productType: item.product,
              })
            )
            yield take(declineDirectCharge.fulfilled)
          }
          break

        case PAYMENT.RECURRENT_CHARGE:
          if (charge.status === RECURRENT_CHARGES.STATUS.PENDING) {
            yield put(
              declineRecurrentCharge.pending({
                paymentId: charge.id,
                productType: item.product,
              })
            )
            yield take(declineRecurrentCharge.fulfilled)
          }
          break

        default:
      }
    }

    if (!preventRedirect && externalCharge && charge.return_url) {
      window.location = charge.return_url
    }
  } catch (error) {
    yield spawn(errorHandler, error, SENTRY.TAGS.CHECKOUT)
  }
}

function* createChargeSaga({ payload }) {
  try {
    const item = yield select(getItemBySlug, payload.slug)
    const agentsCount = yield select(
      getCartAgentsCountForProduct,
      item?.product
    )

    if (item) {
      switch (item.payment.frequency) {
        case PAYMENT.DIRECT_CHARGE:
          yield put(
            createDirectCharge.pending({ app: item, quantity: agentsCount })
          )
          break

        case PAYMENT.RECURRENT_CHARGE:
          yield put(
            createRecurrentCharge.pending({ app: item, quantity: agentsCount })
          )
          break

        default:
      }
    }
  } catch (error) {
    yield spawn(errorHandler, error, SENTRY.TAGS.CHECKOUT)
  }
}

function* archiveChargeSaga({ payload }) {
  try {
    switch (payload?.createdChargeType) {
      case PAYMENT.DIRECT_CHARGE:
        yield put(archiveDirectCharge({ json: payload.charge }))
        break

      case PAYMENT.RECURRENT_CHARGE:
        yield put(archiveRecurrentCharge({ json: payload.charge }))
        break

      default:
    }
  } catch (error) {
    yield spawn(errorHandler, error, SENTRY.TAGS.CHECKOUT)
  }
}

function* getCartInfoRequestSaga({ payload: { cartId } }) {
  try {
    const { json, status } = yield call(cartApi.get, cartId)
    const { summary, closedAt, checkedOutUntil, purchasedAt } = json

    if (status === OK) {
      const cart = {
        ...summary,
        closedAt,
        checkedOutUntil,
        purchasedAt,
        id: cartId,
      }

      yield put(getCartInfo.fulfilled({ cart }))

      // Refresh the cart only if matching the given cartId
      // On thank you page we are creating new cart, and we don't want to update it
      // on page refresh
      const paymentCartId = yield select(getCartId)

      if (paymentCartId === cartId) {
        yield put(cartLoad.fulfilled(json))
      }
    }
  } catch (error) {
    yield put(getCartInfo.rejected({ error: error?.message }))
    yield spawn(errorHandler, error, SENTRY.TAGS.CART)
  }
}

function* checkPurchasedItems(cartId) {
  try {
    const { json, status } = yield call(cartApi.get, cartId)

    if (status === OK) {
      const items = json?.products || []

      return items.reduce(
        (acc, item) => {
          if (item.purchasedAt) {
            acc.purchased.push(item.id)
          } else {
            acc.notPurchased.push(item.id)
          }
          return acc
        },
        { purchased: [], notPurchased: [] }
      )
    } else {
      throw new Error(
        `Couldn't check if all products made through purchase process`
      )
    }
  } catch (error) {
    throw new Error(
      `Couldn't check if all products made through purchase process`
    )
  }
}

function* installPurchasedApps(items) {
  try {
    const installedItemsCalls = items?.map((itemId) =>
      put(installApp.fulfilled({ id: itemId, sourceId: EVENT_SOURCE_IDS.CART }))
    )
    yield all(installedItemsCalls)
  } catch (error) {
    yield spawn(errorHandler, error, SENTRY.TAGS.CHECKOUT)
  }
}

function* purchaseCartSaga() {
  try {
    const cart = yield select(getPaymentCart)

    if (cart) {
      const cartId = cart.id

      switch (true) {
        case cart.purchasedAt:
          throw new SagaError(
            'Cannot purchase already purchased cart',
            null,
            null
          )

        case cart.closedAt:
          throw new SagaError('Cannot purchase closed cart', null, null)

        default:
          const { status, json } = yield call(cartApi.purchase, cartId)

          if (status === OK) {
            const { purchased, notPurchased } = yield call(
              checkPurchasedItems,
              cartId
            )

            yield put(cartCreate.pending({ products: notPurchased }))
            yield put(getCartInfo.pending({ cartId }))

            yield delay(250)
            yield call(installPurchasedApps, purchased)

            pushEvent({
              event: 'checkoutMarketplace',
              payload: {
                cartId,
                appId: purchased,
                failedAppId: notPurchased,
              },
            })

            const notes = notPurchased.reduce(
              (acc, item) => [...acc, item.note],
              []
            )
            const isOnlyInactiveProductError =
              isEveryErrorInactiveProduct(notes)

            if (!notPurchased.length || isOnlyInactiveProductError) {
              yield call(navigate, `/checkout/cart/${cartId}/thank-you`)
            } else {
              yield call(navigate, `/checkout/cart/${cartId}/review-order`)
            }
            yield put(purchaseCart.fulfilled())
          } else if (status === BAD_REQUEST) {
            const { code, message } = getErrorCode(json && json.errors)

            if (code === CART.ERROR_CODE.PRODUCTS_CART_CHECKSUM) {
              yield put(purchaseCart.rejected({ error: code }))
            } else {
              throw new SagaError(message, json, status)
            }
          } else {
            throw new SagaError(`Purchase couldn't be confirmed`, json, status)
          }
      }
    }
  } catch (error) {
    yield put(purchaseCart.rejected({ error: PAYMENT.ERRORS.CART }))
    yield spawn(errorHandler, error, SENTRY.TAGS.CART)
  }
}

function* startCartCheckoutRequestSaga({ payload: { cartId } }) {
  try {
    const { status } = yield call(cartApi.startCheckout, cartId)

    if (status === OK) {
      yield put(startCartCheckout.fulfilled())
    } else {
      throw new SagaError(
        `Start cart checkout failed [${cartId}]`,
        null,
        status
      )
    }
  } catch (error) {
    yield put(startCartCheckout.rejected({ error: error?.message }))
    yield spawn(errorHandler, error, SENTRY.TAGS.CART)
  }
}

function* rejectCartCheckoutRequestSaga({ payload }) {
  try {
    const shouldRedirect = payload?.redirect
    const cart = yield select(getPaymentCart)

    if (!cart?.purchasedAt && !cart?.closedAt) {
      const { status } = yield call(cartApi.rejectCheckout, cart.id)

      if (status === OK) {
        yield put(rejectCartCheckout.fulfilled())

        if (shouldRedirect) {
          navigate('/cart/')
        }
      } else {
        throw new SagaError(
          `Reject cart checkout failed [${cart.id}]`,
          null,
          status
        )
      }
    }

    return null
  } catch (error) {
    yield put(rejectCartCheckout.rejected({ error: error?.message }))
    yield spawn(errorHandler, error, SENTRY.TAGS.CART)
  }
}

export function* directBuyProductNotLoggedInSaga({ payload }) {
  yield take(userLogIn.fulfilled)

  const item = yield select(getItemById, payload.id)

  if (item) {
    yield call(navigate, `/checkout/${item.slug}?embedded=true`)
  }
}

// Scroll to the top every time that Checkout will get an error
// so users on mobile will get the proper notification about error
function* handlePaymentFailed() {
  yield call(window.scrollTo, 0, {})
}

export function* watchPaymentRequests() {
  yield takeEvery(createCharge, createChargeSaga)
  yield takeEvery(acceptCharge, acceptChargeSaga)
  yield takeEvery(declineCharge, declineChargeSaga)
  yield takeEvery(archiveCharge, archiveChargeSaga)
  yield takeEvery(getCartInfo.pending, getCartInfoRequestSaga)
  yield takeEvery(purchaseCart.pending, purchaseCartSaga)
  yield takeEvery(startCartCheckout.pending, startCartCheckoutRequestSaga)
  yield takeEvery(rejectCartCheckout.pending, rejectCartCheckoutRequestSaga)
  yield takeEvery(directBuyNotLoggedIn, directBuyProductNotLoggedInSaga)
  yield takeEvery(startPayment.rejected, handlePaymentFailed)
}
