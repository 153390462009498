import { navigate } from 'gatsby'
import {
  call,
  put,
  select,
  takeEvery,
  take,
  race,
  spawn,
  delay,
} from 'redux-saga/effects'

import { installApp } from '@/store/installedApps'
import { requestUninstallApp } from '@/store/installedApps/sagas'
import { notificationsCreate } from '@/store/notifications'
import { setCreatedChargeInfo, startPayment } from '@/store/payment'
import {
  acceptDirectCharge,
  declineDirectCharge,
  activateDirectCharge,
  createDirectCharge,
  readDirectCharge,
  directChargePollStart,
  directChargePollStop,
  archiveDirectCharge,
} from '@/store/directCharges'

import { OK, CREATED, NOT_FOUND } from '@/constants/utils'
import {
  DIRECT_CHARGES,
  PAYMENT,
  SENTRY,
  NOTIFICATIONS,
  EVENT_SOURCE_IDS,
} from '@/constants'
import taxesApi from '@/apis/billing/taxes'
import directChargeApi from '@/apis/billing/directCharge'
import appsApi from '@/apis/apps'
import { getItemByName } from '@/store/selectors'
import { getExternalChargePaymentInfo } from '@/utils/products'
import { sendPostMessage, PM_EVENT } from '@/utils/browser'
import { errorHandler, SagaError } from '@/utils/sagas'
import { isPublicVersion } from '@/utils'

export function* requestDirectChargeRead({
  payload: { chargeId, productType },
}) {
  try {
    const { status, json } = yield call(
      directChargeApi.read,
      chargeId,
      productType
    )

    if (status === OK) {
      const itemInfo = yield call(appsApi.getAppInfo, json.order_client_id)

      const chargeTaxes = yield call(taxesApi.getPricePreview, {
        price: json.price,
        clientId: json.order_client_id,
        productType: productType,
      })

      let item
      if (itemInfo && itemInfo.json) {
        item = itemInfo.json
      } else {
        item = yield select(getItemByName, json.name)
      }

      const payment = getExternalChargePaymentInfo(
        json,
        PAYMENT.DIRECT_CHARGE,
        chargeTaxes?.json
      )

      yield put(readDirectCharge.fulfilled({ item: json }))
      yield put(
        setCreatedChargeInfo({
          item: { ...item, name: json.name || item.name, payment },
          charge: {
            appId: item.id,
            appSlug: item.slug,
            taxes: payment?.taxes || null,
            ...json,
          },
          chargeType: PAYMENT.DIRECT_CHARGE,
          isExternalCharge: true,
          isProcessable: json.status === DIRECT_CHARGES.STATUS.PENDING,
        })
      )
    } else if (status === NOT_FOUND) {
      yield put(
        notificationsCreate({
          variant: NOTIFICATIONS.VARIANTS.ERROR,
          content: PAYMENT.ERRORS.NOT_FOUND,
          autoHideDelayTime: NOTIFICATIONS.AUTO_HIDE_DELAY_TIME,
          type: isPublicVersion && NOTIFICATIONS.TYPES.PAGE_LOAD,
        })
      )

      yield call(navigate, '/')
      throw new SagaError('Direct charge not found', json)
    } else {
      throw new SagaError('Direct charge fetch failed', json)
    }
  } catch (error) {
    yield spawn(errorHandler, error, SENTRY.TAGS.CHECKOUT)
    yield put(readDirectCharge.rejected({ error: error?.message }))
  }
}

export function* requestDirectChargeCreate({
  payload: { item, quantity = 1 },
}) {
  try {
    const { status, json } = yield call(
      directChargeApi.create,
      item.name,
      item.payment.price,
      quantity,
      null,
      item.authorization.clientId,
      item.payment.perAccount,
      item.product
    )

    if (status === CREATED) {
      const charge = { appId: item.id, appSlug: item.slug, ...json }

      const chargeTaxes = yield call(taxesApi.getPricePreview, {
        price: json.price,
        clientId: json.order_client_id,
        productType: item.product,
      })

      yield put(createDirectCharge.fulfilled({ item: charge }))
      yield put(
        setCreatedChargeInfo({
          item,
          charge: { ...charge, taxes: chargeTaxes?.json || null },
          chargeType: PAYMENT.DIRECT_CHARGE,
          isExternalCharge: false,
          isProcessable: json.status === DIRECT_CHARGES.STATUS.PENDING,
        })
      )
    } else {
      throw new SagaError('Direct charge creation failed', json, status)
    }
  } catch (error) {
    yield spawn(errorHandler, error, SENTRY.TAGS.CHECKOUT)
    yield put(createDirectCharge.rejected({ error: error?.message }))
    yield put(startPayment.rejected({ error: PAYMENT.ERRORS.BILLING_API }))
  }
}

export function* requestDirectChargeActivate({ payload }) {
  try {
    const { paymentId, appId, productType } = payload

    const { status, json } = yield call(
      directChargeApi.activate,
      paymentId,
      productType
    )

    if (status === OK) {
      yield put(
        installApp.fulfilled({
          id: appId,
          sourceId: EVENT_SOURCE_IDS.DIRECT_CHARGE,
        })
      )

      yield put(
        activateDirectCharge.fulfilled({
          item: {
            appId,
            ...json,
          },
        })
      )

      yield put(directChargePollStart(payload))
    } else {
      throw new SagaError(`Direct charge couldn't be activated`, json, status)
    }
  } catch (error) {
    yield spawn(errorHandler, error, SENTRY.TAGS.CHECKOUT)
    yield put(startPayment.rejected({ error: PAYMENT.ERRORS.BILLING_API }))

    yield put(
      activateDirectCharge.rejected({
        error: error?.message,
      })
    )
  }
}

export function* directChargeStatusPolling({
  paymentId,
  appId,
  slug,
  productType,
  isExternalCharge,
}) {
  while (true) {
    try {
      const { json, status } = yield call(
        directChargeApi.read,
        paymentId,
        productType
      )

      const linkToThankYou = !isExternalCharge
        ? `/checkout/${slug}/thank-you`
        : `/checkout/charge/${json?.id}/direct_charge/thank-you`

      switch (json?.status) {
        case DIRECT_CHARGES.STATUS.SUCCESS:
          sendPostMessage(PM_EVENT.APP_PURCHASED, {
            app_id: appId,
          })

          yield call(navigate, linkToThankYou)
          yield put(startPayment.fulfilled())
          yield put(archiveDirectCharge({ json }))
          yield put(directChargePollStop())
          break

        case DIRECT_CHARGES.STATUS.FAILED:
          yield call(requestUninstallApp, { payload: { id: appId } })
          yield put(startPayment.rejected({ error: 'Payment failed' }))
          throw new SagaError(`Direct charge failed`, json, status)

        default:
          yield put(
            directChargePollStart({
              paymentId,
              appId,
              slug,
              productType,
              isExternalCharge,
            })
          )
      }

      yield delay(2000)
    } catch (error) {
      yield spawn(errorHandler, error, SENTRY.TAGS.CHECKOUT)
      yield put(directChargePollStop({ error: error.message }))
    }
  }
}

export function* requestDirectChargeAccept({ payload }) {
  try {
    const { paymentId, appId, productType } = payload

    yield put(startPayment.pending())

    const { status, json } = yield call(
      directChargeApi.accept,
      paymentId,
      productType
    )

    if (status === OK) {
      yield put(
        acceptDirectCharge.fulfilled({
          item: {
            appId,
            ...json,
          },
        })
      )
      yield put(activateDirectCharge.pending(payload))
    } else {
      throw new SagaError(`Recurrent charge couldn't be accepted`, json, status)
    }
  } catch (error) {
    yield spawn(errorHandler, error, SENTRY.TAGS.CHECKOUT)
    yield put(startPayment.rejected({ error: PAYMENT.ERRORS.BILLING_API }))

    yield put(
      acceptDirectCharge.rejected({
        error: error?.message,
      })
    )
  }
}

export function* requestDirectChargeDecline({
  payload: { paymentId, productType },
}) {
  try {
    const { status, json } = yield call(
      directChargeApi.decline,
      paymentId,
      productType
    )

    if (status === OK) {
      yield put(declineDirectCharge.fulfilled({ json }))
      yield put(archiveDirectCharge({ json }))
    } else {
      throw new SagaError(`Recurrent charge couldn't be declined`, json, status)
    }
  } catch (error) {
    yield spawn(errorHandler, error, SENTRY.TAGS.CHECKOUT)
    yield put(startPayment.rejected({ error: PAYMENT.ERRORS.BILLING_API }))
    yield put(
      declineDirectCharge.rejected({
        error: error?.message,
      })
    )
  }
}

export function* watchDirectChargesRequests() {
  yield takeEvery(readDirectCharge.pending, requestDirectChargeRead)
  yield takeEvery(createDirectCharge.pending, requestDirectChargeCreate)
  yield takeEvery(activateDirectCharge.pending, requestDirectChargeActivate)
  yield takeEvery(acceptDirectCharge.pending, requestDirectChargeAccept)
  yield takeEvery(declineDirectCharge.pending, requestDirectChargeDecline)

  while (true) {
    const { payload } = yield take(directChargePollStart)

    yield race([
      call(directChargeStatusPolling, payload),
      take(directChargePollStop),
    ])
  }
}
