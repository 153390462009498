import { isURL } from 'validator'
import { openPopup } from '@/utils/browser'
import {
  isDev,
  isHelpDesk,
  isLabs,
  isLiveChat,
  PRODUCT,
} from '@/utils/environment'
import avatarPlaceholderPath from '@/assets/images/avatar-placeholder.png'

export const buildAccountsUrl = (
  path = '/',
  redirectUri,
  clientId,
  responseType = 'token'
) => {
  const base = process.env.GATSBY_ACCOUNTS_WEBSITE_URL

  return `${base}${path}?redirect_uri=${redirectUri}&response_type=${responseType}&client_id=${clientId}`
}

export const handleDirectInstallationUrlFlow = ({
  clientId,
  directInstallUrl,
  name,
  onClose = undefined,
  openInNewTab,
}) => {
  const flow = 'code'
  const authorizedUrl = buildAccountsUrl('/', directInstallUrl, clientId, flow)

  openPopup({
    url: authorizedUrl,
    winName: name,
    ...(onClose && { onClose }),
    openInNewTab,
  })
}

export const getMarketplaceSettingsLink = (slug) => {
  let prefix

  switch (true) {
    case isHelpDesk:
      prefix = `${process.env.GATSBY_IFRAME_SETTINGS_URL}/integrations`
      break

    case isLiveChat:
    default:
      prefix = `${process.env.GATSBY_IFRAME_SETTINGS_URL}/marketplace`
      break
  }

  return `${prefix}/${slug}`
}

export const getApplicationsSettingsLink = (slug, productType) => {
  const iframeUrl = getProductIFrameUrl(productType)
  const appsPath = getProductApplicationsPath(productType)

  return `${iframeUrl}${appsPath}/${slug}`
}

export const getProductSubscriptionPath = (product) => {
  switch (product) {
    case PRODUCT.LiveChat:
    case '':
      return '/subscription'

    case PRODUCT.HelpDesk:
      return '/subscription'

    default:
      return process.env.GATSBY_PRODUCT_SUBSCRIPTION_PATH
  }
}

export const getProductApplicationsPath = (product) => {
  switch (product) {
    case PRODUCT.LiveChat:
    case '':
      return '/settings/applications'

    case PRODUCT.HelpDesk:
      return '/settings/integrations/apps'

    default:
      return process.env.GATSBY_PRODUCT_APPLICATIONS_PATH
  }
}

export const getProductIFrameUrl = (product) => {
  switch (product) {
    case PRODUCT.LiveChat:
    case '':
      return process.env.GATSBY_LC_IFRAME_URL

    case PRODUCT.HelpDesk:
      return process.env.GATSBY_HD_IFRAME_URL

    default:
      return process.env.GATSBY_IFRAME_URL
  }
}

export const getImageUrlFromCDN = (url) => {
  if (!url) {
    return avatarPlaceholderPath
  }

  const cdnEnv = isDev || isLabs ? 'cdn-labs' : 'cdn'
  const cdnPrefixWithProtocol = `https://${cdnEnv}.livechat-files.com/?uri=`
  const cdnPrefix = `${cdnEnv}.livechat-files.com/?uri=`
  const hasProtocol = isURL(url, { require_protocol: true })
  const isExternalUrl = !url.includes('amazonaws.com')

  switch (true) {
    case url.startsWith(cdnPrefixWithProtocol):
      return url

    case url.includes(cdnPrefix):
      return hasProtocol ? url : `//${url}`

    case isExternalUrl:
      return hasProtocol ? url : `//${url}`

    default:
      const withProtocol = hasProtocol ? url : `https://${url}`
      return `//${cdnPrefix}${withProtocol}`
  }
}

export const getDesignationSubscriptionLink = (designation) => {
  switch (designation) {
    case PRODUCT.HelpDesk:
      return `${process.env.GATSBY_HD_IFRAME_URL}/subscription`

    case PRODUCT.LiveChat:
    default:
      return `${process.env.GATSBY_LC_IFRAME_URL}/subscription`
  }
}
